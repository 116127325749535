/* Variables */
$vin-blue: #5bb7db;
$vin-green: #60b044;
$vin-red: #ff0000;

/* Styles */
body {
  
  font-family: 'Didact Gothic', sans-serif;
  line-height: 1.4em;
  min-width: 230px;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
}


h2, h1, h3, h4, h5 {
  color: $vin-green;
  margin-top: 40px;
}

td {
  font-family: 'Didact Gothic', sans-serif;
}
th {
  font-family: 'Didact Gothic', sans-serif;
  font-weight: bold;
}

span.MuiButton-label {
  font-weight: bold;
}

input[type="file"] {
  font-size: 20px;
  font-family: 'Didact Gothic', sans-serif;
  color: #60b044;
}

div.MuiGrid-root.MuiGrid-container div:first-child {
  font-weight: bold;
}

div.pdf-canvas {
  text-align: center;
}

#app {
  background-color: white;
}
#fileViewerContainer {
  min-height: 100vh;
}

.link {
  color: #60b044;
}
.link:visited {
  color: #60b044;
}